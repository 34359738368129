export const getTotalSizeOfFiles = (surveyData) => {
	const isTypeFile = (questionValue) => {
		return (Array.isArray(questionValue) && questionValue.length && questionValue.every(element => element.content));
	};

	const getSum = (fileQuestionValue) => {
		return fileQuestionValue.reduce((acc, uploadedFile) => acc + (atob(uploadedFile.content.split(',')[1]).length), 0);
	};

	return Object.values(surveyData)
			.filter(questionValue => isTypeFile(questionValue))
			.map(fileQuestionValue => getSum(fileQuestionValue))
			.reduce((acc, sumFileSizePerQuestion) => acc + sumFileSizePerQuestion, 0);
};
