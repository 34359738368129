export const Status = Object.freeze({
	PUBLISHED: 'PUBLISHED',
	UNPUBLISHED: 'UNPUBLISHED'
});

const defaultStyle = `{
	"isTransparentBackground": true,
	"backgroundColor": "#ffffff",
	"fontColor": "#363636",
	"primaryColor": "#057AAB",
	"fontType": "Open Sans"
}`;

export function FormDescriptor({ id, status, recaptchaSiteKey, descriptor, style = defaultStyle } = {}) {
	return { id, status, recaptchaSiteKey, descriptor, style: JSON.parse(style) };
}
