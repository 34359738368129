export const surveyCss = Object.freeze({
	comment: 'field control textarea',
	checkbox: {
		controlLabel: 'content'
	},
	dropdown: {
		title: 'label uf-base-padding',
		root: 'field select'
	},
	error: {
		root: 'notification is-danger uf-notification-new-line'
	},
	file: {
		root: 'sv-file',
		placeholderInput: 'sv-visuallyhidden',
		preview: 'file-upload-preview',
		fileSign: 'sv-hidden',
		fileSignBottom: 'file-upload-name-link',
		fileDecorator: 'file-upload-decorator',
		fileInput: 'sv-visuallyhidden',
		noFileChosen: 'file-upload-no-file-chosen content',
		chooseFile: 'button',
		removeButton: 'sv-hidden',
		removeButtonBottom: 'button is-danger is-small file-upload-clean-button',
		removeFile: 'sv-hidden',
		removeFileSvg: 'file-upload-remove-button'
	},
	header: 'title is-3',
	question: {
		content: 'uf-base-padding',
		title: 'label uf-base-padding',
		requiredText: 'has-text-danger'
	},
	radiogroup: {
		controlLabel: 'content'
	},
	row: 'row-wrapper',
	text: 'field control input'
});
