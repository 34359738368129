import { TinyColor, isReadable } from '@ctrl/tinycolor';
import { fontUrlOf } from './fontUrl';

export default function Styling(setRootProperty) {
	const setStyle = ({ style }) => {
		if (!style) {
			return;
		}
		applyFont(style.fontType);
		setColors(style.isTransparentBackground, style.backgroundColor, style.primaryColor, style.fontColor);
	};

	function applyFont(fontType) {
		if (!fontType) {
			return;
		}
		createFontLink(fontType);
		setRootProperty('--uf-custom-font', `${fontType}, "Arial", sans-serif`);
	}

	function createFontLink(fontType) {
		const userFontUrl = fontUrlOf({ googleFontName: fontType });
		const link = document.createElement('link');
		link.href = userFontUrl;
		link.rel = 'stylesheet';
		document.head.appendChild(link);
	}

	function setColors(isTransparentBackground, backgroundColor, primaryColor, fontColor) {
		if (!validateColor({ primaryColor, backgroundColor, fontColor })) {
			return;
		}

		const nonHexPrimaryColor = new TinyColor(primaryColor);

		setRootProperty('--uf-primary', primaryColor);
		setRootProperty('--uf-primary--2_5--darken', darken(nonHexPrimaryColor, 2.5));
		setRootProperty('--uf-primary--5--darken', darken(nonHexPrimaryColor, 5));
		setRootProperty('--uf-primary--5--lighten', setAlpha(nonHexPrimaryColor, 0.25));
		setRootProperty('--uf-text-color', fontColor);
		setBackgroundColor(isTransparentBackground, backgroundColor);
		setButtonTextColor(primaryColor);
	}

	function validateColor(colors) {
		return Object.values(colors)
				.map(element => new TinyColor(element).isValid)
				.reduce((accumulator, currentValue) => accumulator && currentValue);
	}

	function darken(color, amount) {
		return color.darken(amount).toHexString();
	}

	function setAlpha(color, amount) {
		return color.setAlpha(amount).toRgbString();
	}

	function setBackgroundColor(isTransparentBackground, backgroundColor) {
		if (isTransparentBackground) {
			setRootProperty('--uf-background', 'transparent');
		}
		else {
			setRootProperty('--uf-background', backgroundColor);
		}
	}

	function setButtonTextColor(primaryColor) {
		if (isReadable( primaryColor, '#ffffff', { level: 'AA', size: 'large' })) {
			setRootProperty('--uf-button-text-color', '#ffffff');
		}
		else {
			setRootProperty('--uf-button-text-color', '#000000');
		}
	}

	return { setStyle };
}
