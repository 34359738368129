<template>
	<span class="icon">
		<font-awesome-icon
			:icon="faIcon"
			v-bind="$attrs"
			:class="faSize" />
	</span>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

const DEFAULT_PREFIX = 'td';

export default {
	name: 'TdIcon',
	components: { FontAwesomeIcon },
	props: {
		icon: { type: [String, Array, Object], required: true },
		size: { type: String, default: 'is-medium' }
	},
	data() {
		return {
			faSizeMap: new Map([
				['is-small', 'fas'],
				['is-medium', 'fas fa-lg'],
				['is-large', 'fas fa-2x'],
			])
		};
	},
	computed: {
		faIcon() {
			return typeof this.icon === 'string' ?
				[DEFAULT_PREFIX, this.icon] :
				this.icon;
		},
		faSize() {
			return this.faSizeMap.get(this.size);
		}
	}
};
</script>
