<template>
	<div class="main-area has-user-theme">
		<div v-if="isFetchingData" class="loading-area form-loading-spinner" />
		<div
			v-if="!isFormReadyToBeDisplayed && !isFetchingData"
			ref="formUnavailable"
			class="loading-area has-text-centered">
			<p class="has-text-grey is-italic">{{ $t("universal_forms.hardcoded_form.could_not_load_form") }}</p>
		</div>

		<div v-if="isFormReadyToBeDisplayed && !isFetchingData" class="whole-area">
			<survey ref="surveyForm" :survey="survey" />
			<div class="submit">
				<span
					v-if="errorMessage"
					ref="unsuccessful-response-message"
					class="has-text-justified notification is-danger">
					{{ getErrorMessage }}
				</span>
				<span v-else />
				<submit-button
					ref="surveySubmitButton"
					:disabled="isButtonDisabled"
					:state="getButtonState"
					@click="executeRecaptcha()">
					{{ getSubmitButtonText }}
				</submit-button>
			</div>
		</div>

		<vue-recaptcha
			v-if="getRecaptchaSiteKey"
			ref="recaptcha"
			class="recaptcha-badge"
			size="invisible"
			badge="inline"
			:sitekey="getRecaptchaSiteKey"
			:load-recaptcha-script="true"
			@expired="onCaptchaExpired"
			@verify="submitForm" />
	</div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import SubmitButton from '../widgets/submit-button';
import { surveyModelConfigurator, MAX_FILE_SIZE } from '../survey-js/survey-model-configurator';
import { styling } from '../survey-js/user-theme';
import { getTotalSizeOfFiles } from '../survey-js/survey-file-size-accumulator';
import { Survey } from 'survey-vue';

export default {
	components: { SubmitButton, Survey },
	props: {
		gatewayToken: { type: String, required: true },
		formId: { type: String, required: true },
		surveyDefault: { type: Object, default: surveyModelConfigurator.getDefaultSurveyModel() }
	},
	data() {
		return {
			isFetchingData: true,
			isSubmitting: false,
			survey: this.surveyDefault
		};
	},
	computed: {
		...mapState(['formDescriptor', 'errorMessage', 'submitResponse']),
		...mapGetters(['isFormReadyToBeDisplayed', 'getRecaptchaSiteKey']),
		getButtonState() {
			if (this.isSubmitting) return 'loading';
			if (this.submitResponse) {
				return (this.submitResponse.result === 'successful') ? 'success' : 'failed';
			}
			return 'ready';
		},
		isButtonDisabled() {
			return Boolean(this.submitResponse || this.errorMessage === 'files_exceed_limit');
		},
		getSubmitButtonText() {
			if (this.isSubmitting || this.submitResponse) return '';
			return this.$t('universal_forms.hardcoded_form.submit_button');
		},
		getErrorMessage() {
			if (this.errorMessage === 'files_exceed_limit') {
				return this.$t('universal_forms.error.file_size_too_large');
			}
			if (this.errorMessage === 'form_submit_failed') {
				return this.$t('universal_forms.hardcoded_form.failure_notification');
			}
			return '';
		}
	},
	async created() {
		this.storeGatewayToken({ gatewayToken: this.gatewayToken });
		await this.fetchData();
		this.survey = surveyModelConfigurator.initializeSurveyModel(this.formDescriptor, this.onFileValueChanged);
	},
	methods: {
		...mapActions(['storeGatewayToken', 'fetchFormDescriptor', 'sendForm', 'clearSubmitResponse', 'setErrorMessage', 'clearErrorMessage']),
		async fetchData() {
			await this.fetchFormDescriptor({ formId: this.formId });
			styling.setStyle({ style: this.formDescriptor.style });
			this.isFetchingData = false;
		},
		async submitForm(recaptchaToken) {
			this.$refs.recaptcha.reset();
			this.isSubmitting = true;

			await this.sendForm({ formId: this.formId, formInput: this.survey.data, recaptchaToken: recaptchaToken });

			if (this.submitResponse && this.submitResponse.result === 'successful') {
				this.survey.clear(true);
			}

			this.isSubmitting = false;

			setTimeout(() => this.clearSubmitResponse(), 1500);
		},
		onFileValueChanged() {
			if (getTotalSizeOfFiles(this.survey.data) > MAX_FILE_SIZE) {
				this.setErrorMessage({ errorMessage: 'files_exceed_limit' });
			}
			else {
				this.clearErrorMessage();
			}
		},
		onCaptchaExpired() {
			this.$refs.recaptcha.reset();
		},
		executeRecaptcha() {
			if (!this.survey.hasErrors(true)) {
				this.$refs.recaptcha.execute();
			}
		}
	}
};
</script>

<style lang="scss" scoped>
.main-area {
	display: grid;
	height: 100%;
	grid-template-columns: 1fr;
	grid-template-rows: 6rem 1fr;
	padding: 1rem;

	.whole-area {
		grid-column: 1 / 1;
		grid-row: 1 / 3;
	}

	.loading-area {
		grid-column: 1 / 1;
		grid-row: 2 / 3;
	}
}

.submit {
	display: flex;
	justify-content: space-between;
}

.recaptcha-badge {
	margin-top: 0.5rem;
}

@keyframes spinner {
  to {transform: rotate(360deg);}
}

.form-loading-spinner:before {
  content: '';
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 50px;
  height: 50px;
  margin-top: -15px;
  margin-left: -15px;
  border-radius: 50%;
  border: 1px solid #ccc;
  border-top-color: #07d;
  animation: spinner .6s linear infinite;
}
</style>
