<template>
	<div class="position-middle">
		<div class="notification" :class="messageType">
			<button class="delete" @click="$emit('close')" />
			<slot ref="messageBoxText" name="message" />
		</div>
	</div>
</template>

<script>
export default {
	name: 'MessageBox',
	props: {
		messageType: { type: String, required: true }
	}
};
</script>
