import { render, staticRenderFns } from "./the-form.vue?vue&type=template&id=f0ad8500&scoped=true"
import script from "./the-form.vue?vue&type=script&lang=js"
export * from "./the-form.vue?vue&type=script&lang=js"
import style0 from "./the-form.vue?vue&type=style&index=0&id=f0ad8500&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f0ad8500",
  null
  
)

export default component.exports