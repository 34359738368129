import { Status } from '../domain/form-descriptor';

export function Store({ gateway }) {
	const state = {
		gatewayToken: '',
		formDescriptor: {},
		errorMessage: '',
		submitResponse: null
	};

	const getters = {
		isFormReadyToBeDisplayed({ errorMessage, formDescriptor }) {
			return errorMessage !== 'fetch_error' && formDescriptor?.status === Status.PUBLISHED &&
			!!formDescriptor?.recaptchaSiteKey && !!formDescriptor?.descriptor;
		},
		getRecaptchaSiteKey({ formDescriptor }) {
			return formDescriptor.recaptchaSiteKey;
		}
	};

	const mutations = {
		saveGatewayToken(state, { gatewayToken }) {
			state.gatewayToken = gatewayToken;
		},
		saveFormDescriptor(state, { formDescriptor }) {
			state.formDescriptor = formDescriptor;
		},
		setErrorMessage(state, error) {
			state.errorMessage = error;
		},
		clearErrorMessage(state) {
			state.errorMessage = '';
		},
		saveSubmitResponse(state, submitResponse) {
			state.submitResponse = submitResponse;
		},
		clearSubmitResponse(state) {
			state.submitResponse = null;
		}
	};

	const actions = {
		async storeGatewayToken({ commit }, { gatewayToken }) {
			commit('saveGatewayToken', { gatewayToken });
		},
		async fetchFormDescriptor({ commit, state }, { formId }) {
			await gateway.getFormDescriptor({ gatewayToken: state.gatewayToken, formId })
					.then(response => commit('saveFormDescriptor', { formDescriptor: response }))
					.catch(() => commit('setErrorMessage', 'fetch_error'));
		},
		async setErrorMessage({ commit }, { errorMessage }) {
			commit('setErrorMessage', errorMessage);
		},
		async clearErrorMessage({ commit }) {
			commit('clearErrorMessage');
		},
		async sendForm({ commit, state }, { formId, formInput, recaptchaToken }) {
			commit('clearErrorMessage');
			await gateway.postFormData({ gatewayToken: state.gatewayToken, formId, formInput, recaptchaToken })
					.then(response => commit('saveSubmitResponse', response))
					.catch(() => {
						commit('saveSubmitResponse', { result: 'failed' });
						commit('setErrorMessage', 'form_submit_failed');
					});
		},
		async clearSubmitResponse({ commit }) {
			commit('clearSubmitResponse');
		}
	};

	return { state, getters, mutations, actions };
}
