import Vue from 'vue';
import VueRouter from 'vue-router';
import TheApp from '../the-app';
import TheForm from '../components/the-form';

Vue.use(VueRouter);

const routes = [
	{ path: '/',
		name: 'the-app',
		component: TheApp,
		children: [
			{ path: ':formId',
				name: 'the-form',
				component: TheForm,
				props: (route) => ({
					formId: route.params.formId,
					gatewayToken: route.query.token
				})
			}
		]
	}
];

export const router = new VueRouter({
	routes,
	mode: 'history',
	base: process.env.PUBLIC_PATH
});
