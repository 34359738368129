import TdIcon from './td-icon';
import SubmitButton from './submit-button';
import MessageBox from './message-box';

import '@fortawesome/fontawesome-svg-core/styles.css';
import { config } from '@fortawesome/fontawesome-svg-core';
config.autoAddCss = false;
config.autoReplaceSvg = false;
config.observeMutations = false;

export default function install(Vue) {
	Vue.component(TdIcon.name, TdIcon);
	Vue.component(SubmitButton.name, SubmitButton);
	Vue.component(MessageBox.name, MessageBox);
}
