import Vuex from 'vuex';
import Vue from 'vue';
import { store as storeSetup } from '../store';

Vue.use(Vuex);

export const store = new Vuex.Store({
	strict: process.env.NODE_ENV !== 'production',
	...storeSetup
});
