import axios from 'axios';
import { FormDescriptor } from '../domain/form-descriptor';

export function Gateway({ get, post } = Axios()) {
	return {
		async getFormDescriptor({ gatewayToken, formId }) {
			const { data } = await get(`/api/publishedForms/${formId}`, { headers: { XGatewayToken: gatewayToken } });
			return FormDescriptor(data);
		},
		async postFormData({ formId, gatewayToken, formInput, recaptchaToken }) {
			const payload = { recaptchaToken: recaptchaToken, fields: formInput };

			const { data } = await post(`/api/publishedForms/submitForm/${formId}`, payload, { headers: { XGatewayToken: gatewayToken } });
			return data;
		}
	};
}

function Axios() {
	return axios.create({ baseURL: process.env.PUBLIC_PATH });
}
