<template>
	<button
		id="submit-button"
		class="button has-min-width is-flex is-justify-content-center"
		:class="getBulmaClass"
		type="submit"
		@click="$emit('click')">
		<td-icon v-if="!!getIcon" :icon="getIcon" />
		<span>
			<slot />
		</span>
	</button>
</template>

<script>
import { tdClose24, tdCompleted24 } from '@topdesk/td-icons';
import { library } from '@fortawesome/fontawesome-svg-core';
library.add(tdClose24, tdCompleted24);

export default {
	name: 'SubmitButton',
	props: {
		state: { type: String, required: true }
	},
	data() {
		return {
			stateBulmaClassMap: new Map([
				['success', 'is-success'],
				['failed', 'is-danger'],
				['loading', 'is-loading is-primary'],
				['ready', 'is-primary']
			])
		};
	},
	computed: {
		getIcon() {
			if (this.state === 'success') return 'completed-24';
			if (this.state === 'failed') return 'close-24';
			return '';
		},
		getBulmaClass() {
			return this.stateBulmaClassMap.get(this.state);
		}
	}
};
</script>

<style lang="scss" scoped>
.has-min-width {
	min-width: 6rem;
}
</style>
