import { surveyCss } from './survey-style-settings';

const _9MB = 9437184; // 9 x 1024 x 1024;

export const MAX_FILE_SIZE = _9MB;

export function SurveyModelConfigurator(SurveyVueConstructor) {
	const getDefaultSurveyModel = () =>  {
		return () => SurveyVueConstructor();
	};

	const initializeSurveyModel = (formDescriptor, fileValueChanged) => {
		const survey = SurveyVueConstructor(formDescriptor.descriptor);

		setGeneralStyle(survey);
		setDateQuestionStyle(survey);
		hideNavigationButtons(survey);
		limitFileFields(survey);
		subscribeToFileChangedEvents(survey, fileValueChanged);

		return survey;
	};

	return { getDefaultSurveyModel, initializeSurveyModel };
}

function setGeneralStyle(survey) {
	survey.css = surveyCss;
}

function setDateQuestionStyle(survey) {
	survey.onUpdateQuestionCssClasses.add((survey, options) => {
		const classes = options.cssClasses;
		if (options.question.inputType === 'date') {
			classes.root += ' width-auto';
		}
	});
}

function hideNavigationButtons(survey) {
	survey.showNavigationButtons = 'none';
}

function limitFileFields(survey) {
	survey.getAllQuestions().forEach(question => {
		if (question.maxSize >= 0) {  // Only file fields have maxSize property
			question.maxSize = MAX_FILE_SIZE;
		}
	});
}

function subscribeToFileChangedEvents(survey, fileValueChanged) {
	survey.onValueChanged.add(function(sender, options) {
		if (options.question.getType() === 'file') {
			fileValueChanged();
		}
	});
}
